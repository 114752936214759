

<template>
  <div class="col rounded-3 bg-white min-vh-100 py-2 px-3">
    <!-- Provenance ID-->
    <div class="d-flex flex-wrap">
      <div class="d-sm-inline-flex me-2 text-muted fw-bold">
        Provenance ID :
      </div>
      <div class="flex-fill w-75">
        <DropdownInput :options = "optionsList" :value = "provenanceId" @on_change_input_value = "onProvenanceIdChange"
        class="rounded-3" :class="{'border border-2 border-danger':provenanceId === undefined}"
        :placeholder="'Plese click here to choose a Provenance ID'" ></DropdownInput>
      </div>
    </div>
    <!-- Provenance -->
    <div class="d-flex overflow-auto py-3 px-1 mb-4">
      <!-- loading -->
      <div v-if="this.isLoading" class="d-flex ">
        <div class="block border border-secondary rounded-3" style="min-width: 150px; min-height:180px">
          <div id="header" class="border-bottom border-secondary d-flex p-2 ">
            <div class="col placeholder-glow"> 
              <span class="placeholder w-75"></span>
            </div>
          </div>
          <div id="body" class="p-2 placeholder-glow">
            <span class="placeholder placeholder-sm col-6 bg-secondary"></span>
            <span class="placeholder col-10 bg-secondary"></span>
            <span class="placeholder placeholder-xs col-5 bg-secondary"></span>
          </div>
        </div>
      </div>
      <!-- block -->
      <div v-else class="d-flex" v-for="block,index in blocks" :key="index">
        <div class="block border border border-secondary rounded-3" style="max-width: 18rem; height:200px" @click="showMore(block.entryHash)" :class="[this.blk.entryHash===block.entryHash? 'active':'']" >
          <div id="header" class="border-bottom border-secondary d-flex p-2">
            <div class="col">Block{{index+1}} </div>
            <div class="col-auto" >
              <btn v-if="block.verify === 'Pending'" :name= "block.verify" :color="'light-yellow'" class="btn-sm" @click="verifyBlock()" :LoadingStatus="vbisLoading"></btn>
              <btn v-else-if="block.verify === 'Verified'" :name= "block.verify" :color="'light-green'" class="btn-sm"></btn>
              <btn v-else-if="block.verify === 'Unsuccessful verify'" :name= "block.verify" :color="'light-red'" class="btn-sm"></btn>
            </div>
          </div>
          <div id="body" class="p-2"> 
            <p>Created Date: {{block.createdDate}}</p>
            <p class="text-truncate" style="max-width: 12rem;">EntryHash: {{block.entryHash}}</p>
            <p class="text-truncate" style="max-width: 12rem;">EntryData: {{block.hashdata}}</p>
          </div>
        </div>
        <div id="conection"  class="py-4" style="min-width: 5rem; color:rgb(109,210,48);">
          <div class="position-relative" style="border: 1px solid rgb(109,210,48)">
            <div class="position-absolute top-0 start-50 translate-middle rounded p-0" >
              <span class="p-auto">
                <i class="bi bi-lock-fill rounded text-white " style="background-color:rgb(109,210,48);"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- redirect to create new block -->
      <router-link  
      class="block border border border-secondary rounded-3 position-relative" 
      :class="{'d-none': this.isLoading}"
      style="min-width: 150px; min-height:180px" 
      :to="{ name:'CreateBlock', query:{ keyphraseArr: this.keyphraseArr } }" 
      @click="searchKeyphrase()">
        <div id="header" class="border-bottom border-secondary d-flex p-2" style="text-decoration: none;"> 
          Add a new block
        </div>
        <div class="position-absolute top-50 start-50 translate-middle">
          <span class="fs-1" style="color:rgb(109,210,48);"><i class="bi bi-shield-fill-plus"></i></span>
        </div>
      </router-link>
    </div>
    
    <!-- Block Details -->
    <div v-if="!this.blk">
      <span>Select a block to display.</span>
    </div>
    <div v-else class="table-responsive"> 
      <span class="text-muted fw-bold">Block Details</span>
      <table class="table border" >
          <tbody>
            <tr v-for="(value,key) in this.blk" :key="key">
              <th class="col-auto text-capitalize">{{key}}</th>
              <td v-if="key==='metadataJson'">
                <p v-for="(value,key) in value" :key="key">
                  <span class="fw-bold">{{key}} : </span> 
                  <span class="text-truncate" @click="display(key,value)" role="button">{{value}}</span> 
                </p>
              </td>
              <td class="text-truncate" v-else>
                {{value}}
              </td>
            </tr>
          </tbody>
      </table>
    </div>
    <div id="modal-from">
      <modal-from :showModal="fileDisplay" :title="filetitle" @closeModal="closeModal">
        <template #body >
          <div v-if="this.img">
            <img :src=imgsrc :alt=filetitle class="w-100">
          </div>
          <div v-else>
            <embed :src=pdfsrc class="w-100 min-vh-100" :alt=filetitle />
          </div>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary btn-sm" @click="download"> Download </button>
            <button type="button" class="btn btn-secondary btn-sm" @click="closeModal"> Close </button>
        </template>
      </modal-from>
    </div>
  </div>
</template>

<script>
import DataServices from '@/services/data-service';
import Btn from '../../components/Btn.vue';
import Fn from '@/components/function.js';
import DropdownInput from '../../components/DropdownInput.vue';
import ModalFrom from '../../components/Modal From.vue';

export default {
  components: { 
    Btn, 
    DropdownInput,
    ModalFrom, 
  },
  name:"Provenance",
  data(){
    return{
      provenanceId:'',
      optionsList:[],
      isLoading:true,
      blocks:[],
      blk:'',
      keyphraseArr:[],
      fileDisplay:false,
      filetitle:'',
      vbisLoading:true,
      img:true
    }
  },
  methods: {
    async provenanceidList() {
      let data = await DataServices.GetData();
      data.forEach(x=>{
      this.optionsList.push(x.provenanceId)
      })
      return this.optionsList
    },
    onProvenanceIdChange(x){
      this.provenanceId = x
      this.Provenance() 
      this.blk = ''
    },
    async Provenance(){
      this.isLoading = true;
      let chain = await DataServices.GetProvenance(this.provenanceId)
      this.blocks = chain[0].blocks
      this.blocks = this.blocks.map((x) => {
        return {
          ...x,
          verify: "Pending",
        }
      })
      this.blocks = this.blocks.sort(Fn.dynamicSort('createdDate'))
     
      this.blocks.forEach(async (block) => {
        let x = JSON.parse(block.metadataJson)
        Object.keys(x).forEach(key => {
          const y = key.toLowerCase()
          x[y] = x[key]
          // delete x[key]
          if(y === 'keyphrase' || key === 'keyphrase'){
            this.keyphraseArr.push(x[key])
          }
        })
        this.keyphraseArr=[...new Set(this.keyphraseArr)]

        block.metadataJson = JSON.parse(block.metadataJson)
      })
      this.verifyBlock()
      this.isLoading = false;

    }, 
    async verifyBlock(){
      this.blocks.forEach(async (block) => {
        if (block.verify === "Pending"){
          this.vbisLoading = true
          let data = {"entryHash":block.entryHash,"metadata":block.metadataJson};
          const vb = await DataServices.VerifyBlock(data)
           vb === true ? block.verify = "Verified" :
           vb === false ? block.verify = "Unsuccessful verify":''
        } 
        this.vbisLoading = false
      })
    },
    showMore(entryHash){
        this.blk = this.blocks.find(obj => obj.entryHash === entryHash)
    },
    display(k,v){
      this.filetitle = k
      this.fileDisplay = true
      if (v.includes('png')||v.includes('jpg')||v.includes('jpeg')){
        this.img = true
        this.imgsrc = v
        this.pdfsrc = ''
      } else if (v.includes('pdf')){
        this.imgsrc = ''
        this.img = false
        this.pdfsrc = v
      } else{
        this.img = false
        this.imgsrc = ''
        this.pdfsrc = v
      }
    },
    closeModal(){
      this.fileDisplay = false
      this.src = null
    },
  },
  async mounted(){
    this.provenanceidList(),
    this.Provenance()
  },
  created(){
      this.provenanceId = this.$route.query.provenanceId
  }
}
</script>

<style scoped>
.block:hover{
  border: 1px solid rgb(109,210,48) !important;
  background-color: rgb(250,255,248);
  box-shadow: 2px 2px 5px grey;
}
.active{
  border: 1px solid rgb(109,210,48) !important;
  background-color: rgb(250,255,248);
  box-shadow: 2px 2px 5px grey;
}

/* width */
::-webkit-scrollbar {
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
</style>