<template>
  <div class="col rounded-3 bg-white min-vh-100 p-3 ">
    <form>
      <!-- provenance ID -->
      <div class="d-flex flex-wrap mb-3">
        <!-- <div class="col-auto me-2" > -->
        <div class="d-sm-inline-flex me-2 fw-bold" >
          Entry Hash:
        </div>
        <!-- <div class="col-md-8 col-sm-6 col-12"> -->
        <div class="flex-fill w-75">
          <textarea class="form-control w-100" v-model="data.entryHash" :class="{'is-invalid':v$.entryHash.$error}"></textarea>
          <div v-if="v$.entryHash.$error" class="px-2 text-danger" style="font-size: 90%"> 
            <span> {{v$.entryHash.$errors[0].$message}} </span> 
          </div>
        </div>
      </div>
      <!-- Metadata Container -->
      <div class="">
        <!-- <div class="pb-2" :class="{'d-flex': Object.keys(data.metadata).length !== 0}"> -->
        <div class="d-flex pb-2">
          <div id="title" class="me-auto fw-bold">
            Metadata
          </div>
          
          <div id="Edit/DoneBtns" class="d-inline-flex" v-if="Object.keys(data.metadata).length !== 0">
            <btn v-if="edit" @click="editDone" :name="'Done'" class="btn-sm p-1"></btn>
            <btn v-else @click="editMetadata" :name="'Edit'" class="btn-sm"></btn>
          </div>
        </div>

        <div id="metadata" class="pb-2" v-if="Object.keys(data.metadata).length !== 0">
          <div v-if="edit">
            <textarea v-model="metadataString" class="form-control" :class="{'is-invalid':metadataStringError}" style="height:20rem"></textarea>
            <span v-if="metadataStringError" class="invalid-feedback" style="">{{metadataStringErrorMessage}}</span>
          </div>
          <div v-else>
            <ul class="m-0 p-0" style="list-style-type: none;">
              <li v-for="(value,key) in data.metadata" :key="key" class="text-truncate">
                <span>{{key}}: &emsp;</span>
                <span class="text-muted" >{{value}}</span>
              </li>
            </ul>
          </div>
        </div>
        
        <div id="key-value" :class="{'d-none' :edit}">
          <!-- <div id="FileUploader" class="text-end">
            <input type="file" @change="getFiles($event)" ref='file' class="d-none" accept="image/png, image/jpeg, application/pdf">
            <span class="border-0" role="button" @click="UploadFile" aria-label="Upload a file" 
            data-bs-toggle="tooltip" title="Can only upload png,jpeg or pdf file only.">
              <i class="bi bi-paperclip"></i>Upload a file
            </span>
          </div> -->
          <div class="mb-2 d-sm-flex">
            <div id="KEY" class=" mb-2 me-2">
              <textarea type="text" v-model="state.key" placeholder="Title" class="form-control" :class="{'is-invalid':b$.key.$error}"></textarea>
              <div class="invalid-feedback" v-if="b$.key.$error">
                {{b$.key.$errors[0].$message}}
              </div>
            </div>
            <div id="VALUE" class="flex-grow-1">
              <textarea type="text" v-model="state.value" placeholder="Details" class="form-control" :class="{'is-invalid':b$.value.$error}"></textarea>
              <div class="invalid-feedback" v-if="b$.value.$error">
                {{b$.value.$errors[0].$message}}
              </div>
            </div>
          </div>
          <div class="mb-2" >
            <!-- <Btn @click="add" :name="'Add'" class="btn-sm" style="height: 3.8rem;"></Btn> -->
            <Btn @click="add" :name="'Add'" class="btn-sm"></Btn>
          </div>
        </div>
      </div>
      <!-- verify Block Buttons group  -->
      <div id="CreateBlockBtns"  class="text-end" role="group" aria-label="Button group" >
        <Btn @click="verify" :name="'Verify'" :color="'green'" class="me-2"></btn>
        <Btn @click="reset" :name="'Reset'" :color="'grey'"></btn>
      </div>
    </form>
    <!-- Verified record -->
    <div v-if="record.length > 0">
      <strong> Verified record </strong> 
      <ul class="list-group">
        <li class="list-group-item" v-for="item,index in record" :key="index" :class="[item.verification=== true? 'list-group-item-success':item.verification=== false? 'list-group-item-danger':'']"
        data-bs-toggle="tooltip" title="Click the Entry Hash to show more or less.">
          <span data-bs-toggle="collapse" :data-bs-target='"#_"+index' role="button" aria-expanded="false" aria-controls="collapse"> Entry Hash: {{item.entryHash}} </span>
          <div class="collapse" :id='"_"+index'>
            <span>Metadata : {{item.metadata}}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { reactive } from '@vue/reactivity';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import DataServices from '../../services/data-service';
import Btn from '@/components/Btn.vue';

export default {
  name:"CreateBlock",
  components: { Btn },
  data(){
    return{
      edit:false,
      metadataStringError:false,
      metadataStringErrorMessage:'',
      record:[],
      showCollapse:false
    }
  },
  setup(){
    const data = reactive({
      entryHash: '',
      metadata: {},
    });

    const dataRules = {
      entryHash: { required },
      metadata:{ required },
    };

    const state = reactive({
      key:'',
      value:''
    })
    const stateRule = {
      key: { required },
      value: { required },
    }

    const v$ = useVuelidate(dataRules, data);
    const b$ = useVuelidate(stateRule, state);

    return{
      data, v$, state, b$
    }
  },
  methods:{
    editMetadata(){
      this.edit = true;
      this.metadataString = JSON.stringify(this.data.metadata)
      this.data.metadata = JSON.parse(this.metadataString)
    },
    editDone(){
      try {
        this.data.metadata = JSON.parse(this.metadataString)
        this.metadataStringError = false;
        this.edit = false;
      } catch(e) {
        this.metadataStringError = true;
        this.metadataStringErrorMessage = e.toString()
      }
    },
    // UploadFile(){
    //   this.$refs.file.click();
    // },
    // getFiles(files){
    //   let fileType = files.target.files[0].type
    //   let fileSize = files.target.files[0].size
    //   if ((fileType === 'application/pdf' || fileType === 'image/jpeg' || fileType === 'image/png') && fileSize <= 1000000){ //68000=60kb
    //     const reader = new FileReader()
    //     reader.onload = (e) => {
    //       this.state.value = e.target.result
    //       this.state.key = files.target.files[0].name
    //     }
    //     reader.onerror = (e) =>{
    //       alert(e);
    //     }
    //     reader.readAsBinaryString(files.target.files[0])  
    //   } else {
    //    alert('accept png, jpg, and pdf files and total file size less than 68kB.')
    //   }
    // },
    add(){
      this.b$.$touch()
      if ( this.b$.$error === false ) {
        this.data.metadata[this.state.key.trim()] = this.state.value
        this.state.key = ''
        this.state.value =''
        this.b$.$reset()
      }
    },
    reset(){
      this.data.entryHash = ''
      this.data.metadata = {}
      this.v$.$reset()
      this.state.key = ''
      this.state.value =''
      this.b$.$reset()
      this.edit = false
      this.metadataString = ''
      this.metadataStringError = false
    },
    async verify(){
      this.edit === true ? this.editDone() :
      this.add()
      this.v$.$touch()
      if (this.v$.$error === false){
        let data = {
          'entryHash': this.data.entryHash,
          "metadata": this.data.metadata
        }
        data = {
          ...data,
          verification: await DataServices.VerifyBlock(data)
        }
        this.record.push(data)
        this.reset()
      }
    },
    togglecollapse(){
    this.showCollapse = !this.showCollapse
    }
  }
}
</script>
